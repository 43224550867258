import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ButtonLink } from 'styleguide/components/Buttons/Button';
import { LogoIcon } from 'styleguide/components/Logos/Logo';
import { media, mediaBreakpoints, withWindow } from 'styleguide/helpers';

import solutionsLg from './images/bg-solutions@3x.jpg';
import globalLg from './images/bg-global@3x.jpg';
import groupLg from './images/bg-valio-group@3x.jpg';

import solutionsSm from './images/bg-solutions@2x.jpg';
import globalSm from './images/bg-global@2x.jpg';
import groupSm from './images/bg-valio-group@2x.jpg';
import HandshakeIcon from 'styleguide/components/Icons/MiscIcons/HandshakeIcon';
import StrokeInfoIcon from 'styleguide/components/Icons/MiscIcons/StrokeInfoIcon';
import { GlobeIcon } from 'styleguide/components/Icons';
import { useSelector } from 'react-redux';
import { State } from '../../../reducers';
import { getInitiallyRenderedPath } from '../../../../utils/routing';

const BlueButton = styled(ButtonLink)``;
const WhiteButton = styled(ButtonLink)``;

const AppletWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	z-index: ${({ theme }) => theme.zIndices.modal};
	overflow-y: auto;
`;

const GridWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: flex-start;
	${media.desktop`
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-rows: 84px 1fr 33.3333%;
		width: 100vw;
		height: 100vh;
	`}
`;

const HeaderWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.brandPrimary};
	position: sticky;
	top: 0;
	flex: 0 0 40px;
	z-index: 2;
	${media.desktop`
		position: relative;
		grid-column: span 2;
	`}
`;

const LogoWrapper = styled.div`
	position: absolute;
	left: 50%;
	bottom: -18px;
	transform: translateX(-50%);
	width: 64px;
	${media.desktop`
		width: 115px;
		bottom: -15px;
	`}
`;

const BackgroundContainer = styled.div`
	position: relative;
	padding: ${({ theme }) => theme.grid.gutterInPx(5)} 0;
	cursor: pointer;

	&:before {
		transition: all ease-out 350ms;
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 1;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	@media (hover: hover) {
		&:hover {
			&:before {
				opacity: 0;
			}

			${BlueButton} {
				background-color: ${({ theme }) => theme.colors.buttonPrimaryHover};
			}
			${WhiteButton} {
				background-color: ${({ theme }) => theme.colors.white90};
			}
		}
	}
`;

const ForCompaniesWrapper = styled(BackgroundContainer)`
  background-color: ${({ theme }) => theme.colors.white};

  &:before {
    background-image: url('${solutionsSm}');
    ${media.desktop`
      background-image: url('${solutionsLg}');
    `}
  }
`;

const ValioGroupWrapper = styled(BackgroundContainer)`
	color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.brandPrimary};

  &:before {
    background-image: url('${groupSm}');
    ${media.desktop`
      background-image: url('${groupLg}');
    `}
  }
`;

const CenteredContentWrapper = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-content: center;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 2;
`;

const ContentColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: flex-start;
	text-align: center;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};
	align-items: center;
	max-width: 380px;

	${media.tablet`
    max-width: 30vw;
    min-width: 300px;
  `}
`;

const SplitPart = styled.div``;
const InlinePart = styled(SplitPart)``;

const ValioGlobalWrapper = styled(BackgroundContainer)`
	color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.brandSecondary};

  &:before {
    background-image: url('${globalSm}');
    ${media.desktop`
      background-image: url('${globalLg}');
      background-position: center -150px;
    `}
  }

	${media.desktop`
		grid-column: span 2;
	`}
`;

const IconWrapper = styled.div`
	min-width: 60px;
	width: 60px;
	height: 60px;
	margin: 0 auto;
	align-self: center;
	text-align: center;

	> img {
		height: 100%;
		width: 100%;
	}
`;

const ContentRow = styled(ContentColumn)`
	${media.desktop`
    display: grid;
		grid-template-columns: 50% 50%;
		column-gap: ${({ theme }) => theme.grid.gutterInPx(8)};
    justify-content: center;
    align-content: center;
    max-width: 75vw;

    ${SplitPart} {
      justify-content: flex-start;
      align-content: center;
      align-self: flex-start;
      text-align: left;

      ${IconWrapper} {
        margin-right: ${({ theme }) => theme.grid.gutterInPx(3)};
      }
    }

		${InlinePart} {
			display: flex;
      align-content: flex-start;
		}
  `}
`;

const TitleWrapper = styled.h1`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 28px;
	font-weight: 900;
	line-height: 1.29;

	${media.desktop`
    margin: ${({ theme }) => theme.grid.gutterInPx(2)} 0;
  `}
`;

const ForCompaniesTitle = styled(TitleWrapper)`
	color: ${({ theme }) => theme.colors.brandPrimary};
`;

const TextWrapper = styled.p`
	font-family: ${({ theme }) => theme.fonts.secondary};
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: 0.19px;
	margin: 0 auto ${({ theme }) => theme.grid.gutterInPx(3)};
`;

const ButtonWrapper = styled.span`
	display: inline-block;
`;

const SESSIONSTORAGEKEY = 'com_welcomescreen_displayed';

const WelcomeScreenApplet: React.FC = () => {
	const theme = useTheme();
	const [shouldShowApplet, setShouldShowApplet] = useState<boolean>(false);
	const path = useSelector((state: State) => state.routing.pathname);

	const setSessionStatus = (w: Window) => {
		w.sessionStorage.setItem(SESSIONSTORAGEKEY, '1');
	};

	const getSessionStatus = (w: Window) => w.sessionStorage.getItem(SESSIONSTORAGEKEY);

	useEffect(() => {
		withWindow(w => {
			const showApplet = getSessionStatus(w) !== '1' && getInitiallyRenderedPath() === path;
			setShouldShowApplet(showApplet);
			setSessionStatus(w);
			if (showApplet) {
				w.document.body.style.setProperty('overflow', 'hidden');
			}
		});
		return () => {
			withWindow(w => {
				w.document.body.style.removeProperty('overflow');
			});
		};
	}, []);

	const getLinkElement = (e: React.MouseEvent<HTMLElement>): HTMLAnchorElement | null => {
		const target = e.currentTarget;
		return target.querySelector('a') as HTMLAnchorElement | null;
	};

	const onWrapperClick = (e: React.MouseEvent<HTMLElement>) => {
		withWindow(w => {
			if (w.innerWidth >= mediaBreakpoints.desktop) {
				getLinkElement(e)?.click();
			}
		});
	};

	return shouldShowApplet ? (
		<AppletWrapper>
			<GridWrapper>
				<HeaderWrapper>
					<LogoWrapper>
						<LogoIcon />
					</LogoWrapper>
				</HeaderWrapper>
				<ForCompaniesWrapper onClick={onWrapperClick}>
					<CenteredContentWrapper>
						<ContentColumn>
							<IconWrapper>
								<HandshakeIcon />
							</IconWrapper>
							<ForCompaniesTitle>Valio For Companies</ForCompaniesTitle>
							<TextWrapper>Dairy ingredients and innovative solutions for the food and nutrition industry.</TextWrapper>
							<ButtonWrapper>
								<BlueButton href="https://www.valio.com/food-solutions-for-companies/" size={'medium'}>
									See our solutions
								</BlueButton>
							</ButtonWrapper>
						</ContentColumn>
					</CenteredContentWrapper>
				</ForCompaniesWrapper>
				<ValioGroupWrapper onClick={onWrapperClick}>
					<CenteredContentWrapper>
						<ContentColumn>
							<IconWrapper>
								<StrokeInfoIcon color={theme.colors.white} />
							</IconWrapper>
							<TitleWrapper>Valio Group</TitleWrapper>
							<TextWrapper>
								Looking for information about Valio, consumer products or sustainability themes?
							</TextWrapper>
							<ButtonWrapper>
								<BlueButton href="/" size={'medium'}>
									Read about Valio Group
								</BlueButton>
							</ButtonWrapper>
						</ContentColumn>
					</CenteredContentWrapper>
				</ValioGroupWrapper>
				<ValioGlobalWrapper onClick={onWrapperClick}>
					<CenteredContentWrapper>
						<ContentRow>
							<InlinePart>
								<IconWrapper>
									<GlobeIcon useHDVariant={true} color={theme.colors.white} />
								</IconWrapper>
								<TitleWrapper>Valio Global</TitleWrapper>
							</InlinePart>
							<SplitPart>
								<TextWrapper>Local Valio contact info and available Valio products in different countries.</TextWrapper>
								<ButtonWrapper>
									<WhiteButton
										href="https://www.valio.com/countries/"
										backgroundColor={theme.colors.white}
										color={theme.colors.brandPrimary}
										backgroundHoverColor={theme.colors.buttonHoverBackground}
										hoverColor={theme.colors.bodyText}
										size={'medium'}>
										Find your Country
									</WhiteButton>
								</ButtonWrapper>
							</SplitPart>
						</ContentRow>
					</CenteredContentWrapper>
				</ValioGlobalWrapper>
			</GridWrapper>
		</AppletWrapper>
	) : null;
};

export default WelcomeScreenApplet;
