import { media } from 'styleguide/helpers';
import styled from 'styled-components';

export const FormContainer = styled.form`
	.form-field {
		margin: 16px auto 24px;
	}
`;

export const FieldSet = styled.section`
	.form-field + .form-field {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};

		${media.tablet`
      margin-top: ${({ theme }) => theme.grid.gutterInPx(4)};
    `};
	}

	label {
		margin-bottom: ${({ theme }) => theme.grid.gutterInPx(0.5)};
	}
`;

export const TitleFieldSet = styled(FieldSet)`
	${media.tablet`
    display: flex;    
  `};
`;

export const Column = styled.div`
	& + & {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};
	}

	${media.tablet`
    flex: 1 0 calc(50% - ${({ theme }) => theme.grid.gutterInPx(2)});
    width: calc(50% - ${({ theme }) => theme.grid.gutterInPx(2)});

    & + & {
      margin-top: 0;
      margin-left: ${({ theme }) => theme.grid.gutterInPx(2)};
    }
  `};

	${media.desktop1200`
    & + & {
      margin-left: ${({ theme }) => theme.grid.gutterInPx(4)};
    }
  `};
`;

export const SectionColumn = styled(Column)`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(4)};
	padding-top: ${({ theme }) => theme.grid.gutterInPx(4)};
	border-top: 1px solid ${({ theme }) => theme.colors.border};

	& + & {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(4)};

		${media.tablet`
      margin-top: 0;
    `};
	}

	${media.tablet`
    padding-top: 0;
    margin-top: 0;
    border-top: none;
  `};
`;

export const FieldGroup = styled.section`
	border-radius: 4px;
	background: ${({ theme }) => theme.colors.border};
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	padding: ${({ theme }) => theme.grid.gutterInPx()};
`;

export const SubTitleFieldGroup = styled(FieldGroup)`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};

	.form-field {
		flex: 1 0 auto;
	}

	button {
		margin-left: ${({ theme }) => theme.grid.gutterInPx()};
	}
`;

export const ACCEPTED_FILE_FORMATS_JPG = '.jpg,.jpeg';
export const ACCEPTED_FILE_FORMATS_PNG = '.png';
export const ACCEPTED_FILE_FORMATS_IMAGES = '.jpg,.jpeg,.png';

export const FormActions = styled.section`
	margin-top: ${({ theme }) => theme.grid.gutterInPx(4)};
	padding-top: ${({ theme }) => theme.grid.gutterInPx(4)};
	border-top: 1px solid ${({ theme }) => theme.colors.border};
	text-align: center;

	button {
		width: 100%;

		${media.tablet`
      width: auto;
      min-width: 288px;
      margin: 0 auto;
    `};
	}

	.save-alert {
		margin-bottom: ${({ theme }) => theme.grid.gutterInPx(4)};
	}
`;
