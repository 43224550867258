import { postJson } from 'utils/service';
import { CouponPayload } from './interface';

async function orderCoupon(siteUrlId: string, payload: CouponPayload) {
	return await postJson(`/api/sites/${siteUrlId}/clearon/`, payload, false);
}

export const clearOnApi = {
	orderCoupon,
};

export type ClearOnApi = typeof clearOnApi;
