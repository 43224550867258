import * as React from 'react';
import styled from 'styled-components';
import { AppletCommonProps } from '../types';

const ContentIFrame = styled.iframe<{ height: string }>`
	width: 100%;
	border: none;
	height: ${({ height }) => height};
	margin: 32px auto;
`;

const FullScreenIFrame = styled.iframe`
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	display: block;
	border: 0;
	position: fixed;
	top: 0;
	left: 0;
`;

function IFrame({ parameters }: AppletCommonProps) {
	if (!parameters) {
		return null;
	}

	const { height, src } = parseParameters(parameters);
	if (!src) {
		return null;
	}

	return height ? (
		<ContentIFrame height={height} src={src} scrolling="yes" />
	) : (
		<FullScreenIFrame src={src} scrolling="yes" />
	);
}

function parseParameters(parameters: string): { [key: string]: string } {
	const parameterArray = parameters.split(' ');
	if (parameterArray.length < 2) {
		return {
			src: parameterArray[0],
		};
	}
	const parameterObject = {};
	parameterArray.forEach(parameter => {
		const keyAndValue = parameter.split(/=(.*)/);
		parameterObject[keyAndValue[0]] = keyAndValue[1];
	});
	return parameterObject;
}

export default IFrame;
