import { createAsyncAction, ActionType, getType } from 'typesafe-actions';
import { WeeklyMenuState, WeeklyMenuContent } from 'common/components/WeeklyMenu/interfaces';

export const weeklyMenuAppletActions = {
	fetchWeeklyMenu: createAsyncAction(
		'WEEKLY_MENU_APPLET/FETCH',
		'WEEKLY_MENU_APPLET/SUCCESS',
		'WEEKLY_MENU_APPLET/ERROR'
	)<undefined, WeeklyMenuContent, string>(),
};

export type WeeklyMenuAppletActions = ActionType<typeof weeklyMenuAppletActions>;

export function weeklyMenuAppletReducer(state: WeeklyMenuState, action: WeeklyMenuAppletActions): WeeklyMenuState {
	if (action.type === getType(weeklyMenuAppletActions.fetchWeeklyMenu.request)) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (action.type === getType(weeklyMenuAppletActions.fetchWeeklyMenu.success)) {
		return {
			...state,
			isLoading: false,
			weeklyMenu: action.payload,
		};
	}

	if (action.type === getType(weeklyMenuAppletActions.fetchWeeklyMenu.failure)) {
		return {
			...state,
			isLoading: false,
			error: action.payload.toString(),
		};
	}

	return state;
}
