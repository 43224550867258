import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import SmallHeader from 'styleguide/components/Typography/SmallHeader';
import { useForm, FormContext } from 'react-hook-form/dist/react-hook-form.ie11';
import { FeedbackFormData } from '../interfaces';
import TextArea from 'styleguide/components/Inputs/TextArea';
import Divider, { DividerDirection, DividerSize } from 'styleguide/components/Divider/Divider';
import PersonalData from './PersonalData';
import { FormContainer, FormActions } from '../../common/EditorComponents';
import { Button } from 'styleguide/components/Buttons/Button';
import ReCaptcha from 'common/components/ReCaptcha/ReCaptcha';
import { withWindow } from 'styleguide/helpers';

const CaptchaContainer = styled.div`
	margin-bottom: 15px;

	> div {
		display: inline-block;
		margin: 0 auto;
	}
`;

interface QuestionFormProps {
	onSubmit: (fieldValues: FeedbackFormData) => void;
}

const QuestionForm: React.FC<QuestionFormProps> = ({ onSubmit }) => {
	const intl = useIntl();

	const methods = useForm<FeedbackFormData>();
	const { handleSubmit, register, errors } = methods;

	const [isRecaptchaValid, setRecaptchaState] = useState<boolean>(false);

	const siteKey = withWindow(w => w.reCaptchaSiteKey);

	return (
		<FormContext {...methods}>
			<FormContainer onSubmit={handleSubmit(onSubmit)}>
				<SmallHeader tagName="h2" className="subheader">
					<FormattedMessage id="applet_feedback_form_questionsorcomments" />
				</SmallHeader>
				<TextArea
					name="message"
					placeholder={intl.formatMessage({ id: 'applet_feedback_form_field_message' })}
					ref={register({ required: intl.formatMessage({ id: 'applet_feedback_form_validation_required' }) })}
					validationError={errors.message?.message}
				/>
				<Divider direction={DividerDirection.horizontal} size={DividerSize.full} />
				<PersonalData />
				<FormActions>
					<CaptchaContainer>
						<ReCaptcha resultCallback={setRecaptchaState} siteKey={siteKey} expiredCallback={setRecaptchaState} />
					</CaptchaContainer>
					{isRecaptchaValid && (
						<Button type="submit">
							<FormattedMessage id="applet_feedback_submit" />
						</Button>
					)}
				</FormActions>
			</FormContainer>
		</FormContext>
	);
};

export default QuestionForm;
