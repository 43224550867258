import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../styleguide/components/Buttons/Button';
import styled from 'styled-components';

export function CookieManagement() {
	return (
		<CenteringWrapper>
			<Button id="ot-sdk-btn" className="ot-sdk-show-settings">
				<FormattedMessage id="applet_cookie_management_button_title" />
			</Button>
		</CenteringWrapper>
	);
}

const CenteringWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin: ${({ theme }) => theme.grid.gutterInPx(4)};
`;
