import { IntlShape } from 'react-intl';
import { PointsCalculatorData, PointsCalculatorHelper } from '../common/PointsCalculatorHelper';

class LactoseIntoleranceCalculator extends PointsCalculatorHelper {
	constructor(intl: IntlShape) {
		super(intl, 'lactose');
	}

	public get data(): PointsCalculatorData {
		return {
			questions: Array.from(Array(11).keys())
				.slice(1)
				.map((val, idx) => ({
					id: `question_${val}`,
					multiSelect: false,
					items: [1, 0],
					isHidden: idx !== 0,
				})),
		};
	}

	protected getIndex(points: number) {
		if (points >= 3) {
			return 1;
		}
		return 0;
	}

	protected getMessage(index: number, points: number): string {
		return this.intl.formatMessage({
			id: `applet_${this.name}_result_index_${index}`,
		});
	}

	protected getSummary(index: number): string {
		return this.intl.formatMessage({ id: `applet_${this.name}_result_summary_${index}` });
	}
}

export default LactoseIntoleranceCalculator;
