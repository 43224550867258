import * as React from 'react';
import { IntlShape } from 'react-intl';
import { AppletCommonProps } from '../types';
import LactoseIntoleranceCalculatorHelper from './LactoseIntoleranceCalculatorHelper';
import PointsCalculatorApplet, { SingleSelectQuestionType } from '../common/PointsCalculatorApplet';
import NutritionIcon from 'styleguide/components/Icons/AspectGroupIcons/NutritionIcon/NutritionIcon';

const createHelper = (intl: IntlShape) => new LactoseIntoleranceCalculatorHelper(intl);

const LactoseIntoleranceCalculator: React.FC<AppletCommonProps> = props => {
	return (
		<PointsCalculatorApplet
			{...props}
			singleSelectInputType={SingleSelectQuestionType.Radio}
			name="lactose"
			helper={createHelper}
			icon={<NutritionIcon />}
		/>
	);
};

export default LactoseIntoleranceCalculator;
