import { useScript } from 'common/hooks/useScript';
import * as React from 'react';
import styled from 'styled-components';
import { AppletCommonProps } from '../types';

const ProductLocatorWrapper = styled.iframe`
	width: 100%;
	height: 770px;
	border: 0;
`;

export function ProductLocator({ parameters }: AppletCommonProps) {
	if (!parameters || parameters.trim().length === 0) {
		return <div />;
	}
	const productLocatorParams = parameters.split(' ');

	let scriptSrc = `https://destinilocators.com/${productLocatorParams[0]}/site/install/`;

	if (productLocatorParams.length > 1) {
		scriptSrc += `?${productLocatorParams[1]}`;
	}

	const locatorPlaceHolder = React.useRef<HTMLDivElement>(null);
	const locatorProps = {
		src: scriptSrc,
		ref: locatorPlaceHolder,
	};

	useScript(locatorProps);

	// Use script should be enough but 3rd party script is broken so creating target iframe already here
	const locator = `https://destinilocators.com/${productLocatorParams[0]}/site/locator.php?`;
	const referrer = '?RFR=' + window.location.protocol + '//' + window.location.hostname;
	const customParameters = '';
	const systemParameters = productLocatorParams.length > 1 ? productLocatorParams[1] : '';
	const iframeSrc = locator + referrer + '&' + customParameters + '&' + systemParameters;

	return (
		<div id="productLocator" ref={locatorPlaceHolder}>
			<ProductLocatorWrapper id="destini" src={iframeSrc} scrolling="no" allow="geolocation" />
		</div>
	);
}
