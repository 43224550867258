import * as React from 'react';
import { useEffect } from 'react';
import { withWindow } from 'styleguide/helpers';
import { AppletCommonProps } from '../types';

let isInitialized = false;

const ChatbotFrontAI: React.FC<AppletCommonProps> = ({ parameters }) => {
	const getChatContainers = (w: Window): HTMLElement[] => {
		const nodes = w.document.querySelectorAll('.front-button-container, #chat-container') as NodeListOf<Element>;
		return Array.from(nodes) as HTMLElement[];
	};

	const setChatBotVisibleState = (isVisible: boolean, w: Window) => {
		const elements = getChatContainers(w);
		elements.forEach(e => (e.style.display = isVisible ? 'block' : 'none'));
	};

	const registerBot = (w: Window) => {
		if (isInitialized) {
			setChatBotVisibleState(true, w);
			return;
		}

		const formationParameters = (parameters && parameters.trim()) || `prod-common-valio`;

		const botScript = document.createElement('script');
		botScript.onload = () => (w as any).chatFormation(formationParameters);
		botScript.src = 'https://static.aim.front.ai/prod-common/chat-formation.min.js';
		botScript.async = true;
		w.document.head.appendChild(botScript);
		isInitialized = true;
	};

	const hideBot = (w: Window) => {
		setChatBotVisibleState(false, w);
	};

	useEffect(() => {
		withWindow(registerBot);
		return () => {
			withWindow(hideBot);
		};
	}, []);

	return null;
};

export default ChatbotFrontAI;
