import * as React from 'react';
import styled from 'styled-components';
import { PackageMark } from 'styleguide/components/Icons';
import { FcCheckIcon, FcXMarkIcon } from 'styleguide/components/Icons/NormalIcons';
import { AppletCommonProps } from '../types';
import { media } from 'styleguide/helpers';
import { useIntl } from 'react-intl';
import {
	AgedUnit,
	FCComparisonConfig,
	FeatureExistsType,
	FeatureStatus,
	getComparisonData,
	ProductAged,
} from './FCProductComparisonHelper';

const AppletWrapper = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
`;

const GridItemBase = styled.div`
	display: flex;
	padding: 8px;
	margin: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
`;

const HeaderItem = styled(GridItemBase)`
	font-weight: 900;
	font-size: 14px;
	display: none;
	position: sticky;
	top: 70px;
	background: ${props => props.theme.colors.bodyBackground};
	z-index: 1;

	${media.tablet`
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	`};

	${media.desktop`
		top: 95px;
	`};
`;

const ProductImage = styled.img`
	max-height: 150px;

	${media.tablet`
		max-height: 120px;
		max-width: 120px;
	`};

	${media.desktop`
		max-height: 150px;
		max-width: none;
	`};
`;

const ProductItem = styled(GridItemBase)`
	font-size: 14px;

	${media.tablet`
		> img:not(${ProductImage}) {
			max-width: 70px;
		}
	`};
	${media.desktop`
	> img:not(${ProductImage}) {
		max-width: 100px;
	}
	`};
`;

const ProductInfoItem = styled(ProductItem)`
	display: flex;
	flex-direction: column;

	border-top: 10px solid ${props => props.theme.colors.border};

	${media.tablet`
border-top: none;
`};
`;

const GridWrapper = styled.div<{ columnCount: number }>`
	display: grid;
	grid-template-columns: 1;

	& ${GridItemBase} {
		border-bottom: 1px solid ${props => props.theme.colors.border};
	}

	& ${HeaderItem} {
		border-bottom: 2px solid ${props => props.theme.colors.border};
	}

	& ${ProductInfoItem} {
		margin-top: 20px;
	}

	${media.tablet<{ columnCount: number }>`
		grid-template-columns: repeat(${props => props.columnCount}, 1fr);

		& ${GridItemBase}:nth-last-child(-n+${props => props.columnCount}) {
			border-bottom: none;
		}

		& ${ProductInfoItem} {
			margin-top: 0;
		}
	`};
`;

const HeaderTitle = styled.span`
	text-align: center;
`;

const ProductTitle = styled.span`
	align-self: center;
	text-align: center;
`;

const ItemTitle = styled.span`
	align-self: center;
	font-weight: 600;
	padding: 4px 0;
	display: flex;

	${media.tablet`
		display: none;
	`};
`;

const ItemValue = styled.span`
	align-self: center;
`;

const PackageSymbolWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: -10px;

	& > div {
		margin: 10px;
	}
	width: 110px;
	height: 118px;

	${media.desktop`
		width: auto;
		height: auto;
`};
`;

function FeatureIcon(status: FeatureStatus) {
	switch (status.iconType) {
		case FeatureExistsType.exists:
			return <FcCheckIcon />;
		case FeatureExistsType.existsWithCaret:
			return <FcCheckIcon iconType="withCaret" />;
		case FeatureExistsType.notExists:
			return <FcXMarkIcon />;
		default:
			return <></>;
	}
}

function AgedComponent(aged: ProductAged) {
	const intl = useIntl();
	const messageKey =
		aged.unit === AgedUnit.days ? 'applet_product_comparison_aged_days' : 'applet_product_comparison_aged_months';

	return <ItemValue>{intl.formatMessage({ id: messageKey }, { agedTime: aged.value })}</ItemValue>;
}

function parseParameters(parameters: string): FCComparisonConfig {
	const parameterArray = parameters.split(' ');

	const config: FCComparisonConfig = { key: '' };

	if (parameterArray.length > 0) {
		config.key = parameterArray[0];
	}

	if (parameterArray.length > 1) {
		config.productImages = parameterArray.slice(1);
	}

	return config;
}

export function FCProductComparison(props: AppletCommonProps) {
	const intl = useIntl();
	if (!props.parameters) {
		return <></>;
	}

	const configMap = parseParameters(props.parameters);

	const data = getComparisonData(configMap);

	if (!data) {
		return <></>;
	}

	const getMessage = (key: string) => {
		return intl.formatMessage({ id: `applet_product_comparison_${key}` });
	};

	return (
		<AppletWrapper>
			<GridWrapper columnCount={data.header.length}>
				{data.header.map((headerValue, index) => (
					<HeaderItem key={`comparison-header-${index}`}>
						{!headerValue.image && <HeaderTitle>{getMessage(headerValue.title)}</HeaderTitle>}
						{headerValue.image && (
							<PackageSymbolWrapper>
								<PackageMark {...headerValue.image} />
							</PackageSymbolWrapper>
						)}
					</HeaderItem>
				))}
				{data.products.map((product, productIndex) => (
					<React.Fragment key={`product-${productIndex}`}>
						<ProductInfoItem key={`product-info-${productIndex}`}>
							<ProductImage src={`${product.imageUrl}/150x150-product`} />
							<ProductTitle>{product.title}</ProductTitle>
						</ProductInfoItem>
						{product.features.map((feature, index) => (
							<ProductItem key={`product-item-${productIndex}-${index}`}>
								<ItemTitle>{getMessage(data.header[index + 1].title)}</ItemTitle>
								{feature.featureKey && <ItemValue>{getMessage(feature.featureKey)}</ItemValue>}
								{feature.iconType && <FeatureIcon {...feature} />}
								{feature.aged && <AgedComponent {...feature.aged} />}
								{feature.packageMark && <PackageMark {...feature.packageMark} />}
							</ProductItem>
						))}
					</React.Fragment>
				))}
			</GridWrapper>
		</AppletWrapper>
	);
}
