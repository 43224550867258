import * as React from 'react';
import { AppletCommonProps } from '../types';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';
import BackgroundVideo from 'styleguide/components/PicturesAndVideos/BackgroundVideo';
import { WIDGET_SPACING_SMALL, WIDGET_SPACING_MEDIUM } from 'common/components/Widgets/constants';
import { getScrollbarWidth } from 'utils/document';
import { ToggleMedia } from 'common/components/Media';

const AppletBackgroundVideo = styled(BackgroundVideo)`
	position: relative;
	width: 100vw;
	margin-left: 0;
	height: 150px;
	margin: ${WIDGET_SPACING_SMALL}px auto;

	${media.phone`
		margin-left: ${({ theme }) => theme.grid.gutterInPx(-2)};
	`};

	${media.tablet`
		height: 300px;
		margin: ${WIDGET_SPACING_MEDIUM}px auto;
		margin-left: ${({ theme }) => theme.grid.gutterInPx(-5)};
	`};

	${media.desktop`
		width: calc(100vw - ${getScrollbarWidth()}px);
		margin: ${WIDGET_SPACING_MEDIUM}px calc(50% - 50vw + ${Math.floor(getScrollbarWidth() / 2)}px);
	`};

	& > div {
		height: 100%;
	}

	video {
		max-height: 100%;
	}
`;

const VideoRibbonApplet: React.FC<AppletCommonProps> = ({ parameters }) => {
	const videoSources = parameters.split(',');

	if (videoSources.length > 1) {
		return (
			<>
				<ToggleMedia displayType="block" toggleType="hide" breakpoint="tablet">
					<AppletBackgroundVideo
						sources={[
							{
								src: videoSources[0],
								type: 'mp4',
							},
						]}
						loop
					/>
				</ToggleMedia>
				<ToggleMedia displayType="block" toggleType="show" breakpoint="tablet">
					<AppletBackgroundVideo
						sources={[
							{
								src: videoSources[1],
								type: 'mp4',
							},
						]}
						loop
					/>
				</ToggleMedia>
			</>
		);
	}

	return (
		<AppletBackgroundVideo
			sources={[
				{
					src: videoSources[0],
					type: 'mp4',
				},
			]}
			loop
		/>
	);
};

export default VideoRibbonApplet;
