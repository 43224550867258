import { TEXT_WIDTH, TEXT_WIDTH_NARROW, WIDGET_SPACING_SMALL } from 'common/components/Widgets/constants';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'styleguide/components/Buttons/Button';
import Input from 'styleguide/components/Inputs/Input';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import { media } from 'styleguide/helpers';
import { AppletCommonProps } from '../types';

const MceFormContainer = styled.div`
	width: 100%;
	max-width: ${TEXT_WIDTH_NARROW}px;
	margin: ${WIDGET_SPACING_SMALL}px auto;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx()};
	color: ${({ theme }) => theme.colors.heading};

	${media.tablet`
		padding: 0;
	`};

	${media.desktop1440`
    max-width: ${TEXT_WIDTH}px;
  `};
`;

const FormContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	${media.tablet`
		flex-direction: row;
	`};
`;

const MceResponse = styled.div`
	display: none;
`;

const SignupWrapper = styled.div`
	position: absolute;
	left: -5000px;
`;

const Title = styled(MediumHeader)`
	text-align: center;

	${media.tablet`
		text-align: inherit;
	`};
`;

const EmailInput = styled(Input)`
	align-self: center;
	margin: ${({ theme }) => theme.grid.gutterInPx(1)} 0;

	${media.tablet`
		margin: 0;
	`};
`;

const SubmitButton = styled(Button)`
	align-self: center;
	margin: ${({ theme }) => theme.grid.gutterInPx(1)} 0;

	${media.tablet`
		margin: 0;
	`};
`;

const BalticMailchimpNewsLetter: React.FC<AppletCommonProps> = (props: AppletCommonProps) => {
	const intl = useIntl();

	return (
		<MceFormContainer id="mc_embed_signup">
			<form
				action="https://valio.us18.list-manage.com/subscribe/post?u=bf12ba6d0926b2fcdedb11d9a&id=31bd7f5b01"
				method="post"
				id="mc-embedded-subscribe-form"
				name="mc-embedded-subscribe-form"
				className="validate"
				target="_blank">
				<FormContent id="mc_embed_signup_scroll">
					<Title tagName="h2">{intl.formatMessage({ id: 'applet_mailchimp_newsletter_form_title' })}</Title>

					<EmailInput
						type="email"
						name="EMAIL"
						id="mce-EMAIL"
						placeholder={intl.formatMessage({ id: 'applet_mailchimp_newsletter_form_field_email_placeholder' })}
						required
					/>
					<div id="mce-responses" className="clear">
						<MceResponse className="response" id="mce-error-response" />
						<MceResponse className="response" id="mce-success-response" />
					</div>

					{/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
					<SignupWrapper aria-hidden="true">
						<input type="text" name="b_bf12ba6d0926b2fcdedb11d9a_31bd7f5b01" tabIndex={-1} value="" />
					</SignupWrapper>
					<SubmitButton id="mc-embedded-subscribe" type="submit" name="subscribe" value="Subscribe">
						{intl.formatMessage({ id: 'applet_mailchimp_newsletter_form_subscribe_button' })}
					</SubmitButton>
				</FormContent>
			</form>
		</MceFormContainer>
	);
};

export default BalticMailchimpNewsLetter;
