import * as React from 'react';
import Field, { FieldProps } from '../Field/Field';
import { FormattedMessage, useIntl } from 'react-intl';
import Image from 'styleguide/components/PicturesAndVideos/Image';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import { ButtonProps } from 'styleguide/interfaces';
import { baseButtonCss, baseFilledButtonCss } from 'styleguide/components/Buttons/common';
import styled from 'styled-components';

const ImagePreviewContainer = styled.div<{ isLoading?: boolean; hasError?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: ${({ theme }) => theme.grid.gutterInPx(2)};
	padding: 16px;
	border: 2px dashed ${({ hasError, theme }) => (hasError ? theme.colors.actionErrorText : theme.colors.border)};
	border-radius: 4px;
	min-height: ${({ theme }) => theme.grid.gutter * 2 + 231}px;
	position: relative;
	background-color: ${({ hasError, theme }) => (hasError ? theme.colors.actionErrorBackground : theme.colors.white)};

	.image-loading {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: 0;
		transform: translate(-50%, -50%);
	}

	img {
		opacity: ${({ isLoading }) => (isLoading ? '0.25' : '1')};
	}

	&.drag-highlight {
		background: ${({ theme }) => theme.colors.border};
		border-style: solid;
		border-color: ${({ theme }) => theme.colors.border};

		img {
			opacity: 0.25;
		}
	}
`;

const Input = styled.input`
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	white-space: nowrap;
	width: 1px;
`;

const UploadLabel = styled.label<ButtonProps>`
	${baseButtonCss};
	${baseFilledButtonCss};
	display: inline-flex;
	margin: 0 auto;
	transition: background-color 0.3s;
`;

export interface FileInputProps extends FieldProps {
	showPreviewImage?: boolean;
	previewImageUrl?: string | null;
	isLoading?: boolean;
	acceptFiles: string;
	onDropImage: (file: File | null) => void;
	onUploadImage: () => void;
}

type Props = FileInputProps & React.InputHTMLAttributes<HTMLInputElement>;

const FileInput = React.forwardRef<HTMLInputElement, Props>(
	(
		{
			id,
			name,
			className,
			validationError,
			helpText,
			label,
			acceptFiles,
			showPreviewImage,
			previewImageUrl,
			isLoading,
			onDropImage,
			onUploadImage,
		},
		ref: React.RefObject<HTMLInputElement>
	) => {
		const intl = useIntl();

		const inputId = id || name;

		const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
			event.preventDefault();

			event.currentTarget.classList.remove('drag-highlight');

			let file: File | null;

			if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
				file = event.dataTransfer.items[0].getAsFile();
			} else {
				file = event.dataTransfer.files[0];
			}

			onDropImage(file);
		};

		const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
			event.preventDefault();
			event.currentTarget.classList.add('drag-highlight');
		};

		const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
			event.currentTarget.classList.remove('drag-highlight');
		};

		return (
			<Field
				validationError={validationError}
				helpText={helpText}
				id={inputId}
				name={name}
				className={className}
				label={label}>
				<ImagePreviewContainer
					className="image-preview-container"
					isLoading={isLoading}
					hasError={!!validationError}
					onDrop={onDrop}
					onDragOver={onDragOver}
					onDragLeave={onDragLeave}>
					{!isLoading && !previewImageUrl && <FormattedMessage id="forms_input_file_drop_image" />}
					{showPreviewImage && previewImageUrl && <Image src={previewImageUrl} />}
					{isLoading && <LoadingSpinner className="image-loading" />}
				</ImagePreviewContainer>
				<Input
					type="file"
					id={inputId}
					name={name}
					accept={acceptFiles}
					ref={ref}
					disabled={isLoading}
					onChange={onUploadImage}
				/>
				<UploadLabel size="medium" htmlFor={inputId} disabled={isLoading}>
					{showPreviewImage && previewImageUrl
						? intl.formatMessage({ id: 'forms_input_file_browse_alternative' })
						: intl.formatMessage({ id: 'forms_input_file_browse' })}
				</UploadLabel>
			</Field>
		);
	}
);

export default FileInput;
