import { uploadFiles, postJson } from 'utils/service';
import { FeedbackApiModel, FeedbackImageApiModel } from './interfaces';

async function sendFeedback(siteUrlId: string, payload: FeedbackApiModel): Promise<void> {
	return await postJson(`/api/feedback/${siteUrlId}/ConsumerEmailHandler`, payload, false);
}

async function uploadFile(siteUrlId: string, file: File): Promise<FeedbackImageApiModel[]> {
	return await uploadFiles(`/api/feedback/${siteUrlId}/Upload`, { file }, true);
}

export const feedbackApi = {
	sendFeedback,
	uploadFile,
};

export type FeedbackApi = typeof feedbackApi;
