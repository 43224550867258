import { createAsyncAction, ActionType, getType } from 'typesafe-actions';
import { ProductSearchResult } from 'common/components/GlobalSearch/services/product-search';
import { ResourceApiShape } from 'common/components/Resource/interfaces';
import { ProductApiContent } from 'pagetypes/Product/interfaces';
import { AsyncLoadableState } from 'common/interfaces/common';
import { FeedbackImageApiModel } from './interfaces';

export interface FeedbackState extends AsyncLoadableState {
	products: ProductSearchResult | undefined;
	resourceImageUrl?: string;
	isImageLoading: boolean;
	attachmentId?: string;
	isImageBeingUploaded: boolean;
	previewImage?: string;
	isSubmitting?: boolean;
	isSubmitted?: boolean;
	errorState?: any;
}

export interface FeedbackApiModel {
	products: ProductSearchResult;
}

export interface FeedbackErrorApiModel {
	error: string;
	modelState: any;
}

export const feedbackActions = {
	fetchProducts: createAsyncAction(
		'FEEDBACK/FETCH_PRODUCTS',
		'FEEDBACK/FETCH_PRODUCTS_SUCCESS',
		'FEEDBACK/FETCH_PRODUCTS_FAILED'
	)<undefined, ProductSearchResult, string>(),
	fetchResource: createAsyncAction(
		'FEEDBACK/FETCH_RESOURCE',
		'FEEDBACK/FETCH_RESOURCE_SUCCESS',
		'FEEDBACK/FETCH_RESOURCE_FAILED'
	)<undefined, ResourceApiShape, string>(),
	sendFeedback: createAsyncAction(
		'FEEDBACK/POST_FEEDBACK',
		'FEEDBACK/POST_FEEDBACK_SUCCESS',
		'FEEDBACK/POST_FEEDBACK_FAILED'
	)<undefined, undefined, FeedbackErrorApiModel>(),
	uploadImage: createAsyncAction(
		'FEEDBACK/UPLOAD_ATTACHMENT',
		'FEEDBACK/UPLOAD_ATTACHMENT_SUCCESS',
		'FEEDBACK/UPLOAD_ATTACHMENT_FAILED'
	)<undefined, { file: File; apiData: FeedbackImageApiModel }, string>(),
};

export type FeedbackActions = ActionType<typeof feedbackActions>;

export function feedbackReducer(state: FeedbackState, action: FeedbackActions): FeedbackState {
	if (action.type === getType(feedbackActions.fetchProducts.request)) {
		return {
			...state,
			isLoading: true,
		};
	}
	if (action.type === getType(feedbackActions.fetchProducts.success)) {
		return {
			...state,
			isLoading: false,
			products: action.payload,
		};
	}

	if (action.type === getType(feedbackActions.fetchProducts.failure)) {
		return {
			...state,
			isLoading: false,
			error: action.payload,
		};
	}

	if (action.type === getType(feedbackActions.fetchResource.request)) {
		return {
			...state,
			resourceImageUrl: undefined,
			isImageLoading: true,
		};
	}

	if (action.type === getType(feedbackActions.fetchResource.success)) {
		const data = action.payload.content as ProductApiContent;
		return {
			...state,
			isImageLoading: false,
			resourceImageUrl: data.packages[0].imageUrl,
		};
	}

	if (action.type === getType(feedbackActions.fetchResource.failure)) {
		return {
			...state,
			isImageLoading: false,
			error: action.payload,
		};
	}

	if (action.type === getType(feedbackActions.sendFeedback.request)) {
		return {
			...state,
			isSubmitting: true,
		};
	}

	if (action.type === getType(feedbackActions.sendFeedback.success)) {
		return {
			...state,
			isSubmitting: false,
			isSubmitted: true,
		};
	}

	if (action.type === getType(feedbackActions.sendFeedback.failure)) {
		return {
			...state,
			isSubmitting: false,
			error: action.payload.error,
			errorState: action.payload.modelState,
		};
	}

	if (action.type === getType(feedbackActions.uploadImage.request)) {
		return {
			...state,
			isImageBeingUploaded: true,
		};
	}

	if (action.type === getType(feedbackActions.uploadImage.success)) {
		const objUrl =
			!!URL && typeof URL.createObjectURL === 'function' ? URL.createObjectURL(action.payload.file) : undefined;
		return {
			...state,
			isImageBeingUploaded: false,
			attachmentId: action.payload.apiData.guid,
			previewImage: objUrl,
		};
	}

	if (action.type === getType(feedbackActions.uploadImage.failure)) {
		return {
			...state,
			isImageBeingUploaded: false,
			error: action.payload,
		};
	}

	return state;
}
