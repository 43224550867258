import { PackageMarkProps } from 'styleguide/components/Icons/PackageMark/PackageMark';

export enum FeatureExistsType {
	exists = 'exists',
	existsWithCaret = 'existsWithCaret',
	notExists = 'notExists',
}

export enum AgedUnit {
	days,
	months,
}

export interface FCComparisonConfig {
	key: string;
	productImages?: string[];
}

export interface ProductAged {
	value: string;
	unit: AgedUnit;
}

export interface FeatureStatus {
	iconType?: FeatureExistsType;
	featureKey?: string;
	aged?: ProductAged;
	packageMark?: PackageMarkProps;
}

export interface HeaderItem {
	title: string;
	image?: PackageMarkProps;
}

export interface ProductItem {
	title: string;
	imageUrl?: string;
	features: FeatureStatus[];
}

export interface ProductData {
	header: HeaderItem[];
	products: ProductItem[];
}

const defaultHeaderImageProps = () => ({
	width: '126px',
	height: '126px',
});

const productDataConfig = (): { [key: string]: ProductData } => ({
	['DeliLoaves']: {
		header: [
			{
				title: 'products',
			},
			{
				title: 'origin_type',
			},
			{
				title: 'free_of_artificial_hormones',
				image: {
					type: 'fcnoartificialhormones',
					name: 'Free of Artificial Hormones',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'good_source_of_protein_calcium',
				image: {
					type: 'fccalciumproteinsource',
					name: 'Good Source of Protein/Calcium',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'non_gmo_ingredients',
				image: {
					type: 'fcnongmo',
					name: 'Non GMO',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'naturally_lactose_free',
				image: {
					type: 'fclactosefree',
					name: 'Naturally Lactose Free',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'aged',
			},
		],
		products: [
			{
				title: 'Gouda',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.existsWithCaret,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
				],
			},
			{
				title: 'Lacey Swiss',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.existsWithCaret,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
				],
			},
			{
				title: 'Swiss - Sandwich Cut',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.existsWithCaret,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						aged: {
							unit: AgedUnit.days,
							value: '100',
						},
					},
				],
			},
			{
				title: 'Swiss - High Cut',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.existsWithCaret,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						aged: {
							unit: AgedUnit.days,
							value: '100',
						},
					},
				],
			},
			{
				title: 'Light Swiss',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.existsWithCaret,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						aged: {
							unit: AgedUnit.days,
							value: '60',
						},
					},
				],
			},
			{
				title: 'Sharp White Cheddar',
				features: [
					{
						featureKey: 'origin_domestic',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
				],
			},
			{
				title: 'Sharp Yellow Cheddar',
				features: [
					{
						featureKey: 'origin_domestic',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
				],
			},
			{
				title: 'Havarti',
				features: [
					{
						featureKey: 'origin_domestic',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
				],
			},
			{
				title: 'Pepper Jack',
				features: [
					{
						featureKey: 'origin_domestic',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
				],
			},
			{
				title: 'Muenster',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						aged: {
							unit: AgedUnit.days,
							value: '60',
						},
					},
				],
			},
			{
				title: 'Lappi',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
				],
			},
		],
	},
	['Butter']: {
		header: [
			{
				title: 'products',
			},
			{
				title: 'origin_type',
			},
			{
				title: 'free_of_artificial_hormones',
				image: {
					type: 'fcnoartificialhormones',
					name: 'Free of Artificial Hormones',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'good_source_of_protein_calcium',
				image: {
					type: 'fccalciumproteinsource',
					name: 'Good Source of Protein/Calcium',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'non_gmo_ingredients',
				image: {
					type: 'fcnongmo',
					name: 'Non GMO',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'naturally_lactose_free',
				image: {
					type: 'fclactosefree',
					name: 'Naturally Lactose Free',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'kosher',
			},
		],
		products: [
			{
				title: 'Perfectly Salted Imported Butter',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
					{
						packageMark: {
							type: 'kosherTriangle',
							name: 'Kosher',
						},
					},
				],
			},
			{
				title: 'Unsalted Imported Butter',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
					{
						packageMark: {
							type: 'kosherTriangle',
							name: 'Kosher',
						},
					},
				],
			},
		],
	},
	['CheeseSlices']: {
		header: [
			{
				title: 'products',
			},
			{
				title: 'origin_type',
			},
			{
				title: 'free_of_artificial_hormones',
				image: {
					type: 'fcnoartificialhormones',
					name: 'Free of Artificial Hormones',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'good_source_of_protein_calcium',
				image: {
					type: 'fccalciumproteinsource',
					name: 'Good Source of Protein/Calcium',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'non_gmo_ingredients',
				image: {
					type: 'fcnongmo',
					name: 'Non GMO',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'naturally_lactose_free',
				image: {
					type: 'fclactosefree',
					name: 'Naturally Lactose Free',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'aged',
			},
		],
		products: [
			{
				title: 'Swiss Imported Premium Cheese Slices',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						aged: {
							unit: AgedUnit.days,
							value: '100',
						},
					},
				],
			},

			{
				title: 'Muenster Imported Premium Cheese Slices',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
				],
			},
			{
				title: 'Havarti Imported Premium Cheese Slices',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
				],
			},
			{
				title: 'Gouda Imported Premium Cheese Slices',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
				],
			},
			{
				title: 'Cheddar-Gruyere Imported Premium Cheese Slices',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
				],
			},
		],
	},
	['SpecialityCheese']: {
		header: [
			{
				title: 'products',
			},
			{
				title: 'origin_type',
			},
			{
				title: 'free_of_artificial_hormones',
				image: {
					type: 'fcnoartificialhormones',
					name: 'Free of Artificial Hormones',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'good_source_of_protein_calcium',
				image: {
					type: 'fccalciumproteinsource',
					name: 'Good Source of Protein/Calcium',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'non_gmo_ingredients',
				image: {
					type: 'fcnongmo',
					name: 'Non GMO',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'naturally_lactose_free',
				image: {
					type: 'fclactosefree',
					name: 'Naturally Lactose Free',
					...defaultHeaderImageProps(),
				},
			},
			{
				title: 'aged',
			},
		],
		products: [
			{
				title: 'Gouda-Parmesan',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						aged: {
							unit: AgedUnit.months,
							value: '7',
						},
					},
				],
			},
			{
				title: 'Cheddar-Gruyere',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						aged: {
							unit: AgedUnit.days,
							value: '60',
						},
					},
				],
			},
			{
				title: 'Gruyere',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						aged: {
							unit: AgedUnit.months,
							value: '6',
						},
					},
				],
			},
			{
				title: 'Forte Classico',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{},
				],
			},
			{
				title: 'Gran Regale',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{},
				],
			},
			{
				title: 'Spreadable cheese, Original',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
					{},
				],
			},
			{
				title: 'Spreadable cheese, Chanterelle Mushroom',
				features: [
					{
						featureKey: 'origin_imported',
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
					{
						iconType: FeatureExistsType.exists,
					},
					{
						iconType: FeatureExistsType.notExists,
					},
					{},
				],
			},
		],
	},
});

export const getComparisonData = (configMap: FCComparisonConfig): ProductData | undefined => {
	const config = productDataConfig();

	if (config[configMap.key]) {
		const comparisonData = config[configMap.key];

		if (configMap.productImages && configMap.productImages.length > 0) {
			for (let index = 0; index < comparisonData.products.length; index++) {
				if (configMap.productImages.length >= index + 1) {
					comparisonData.products[index].imageUrl = configMap.productImages[index];
				}
			}
		}

		return comparisonData;
	}

	return undefined;
};
