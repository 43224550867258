import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { WIDGET_SPACING_SMALL } from 'common/components/Widgets/constants';
import { TEXT_WIDTH_NARROW, TEXT_WIDTH } from 'common/components/Widgets/constants';
import { media } from 'styleguide/helpers';
import { Forms } from './types';
import { AppletCommonProps } from '../types';
import { connect, MapStateToProps } from 'react-redux';
import { State } from 'common/reducers';
import { getSiteEnvironment } from 'common/components/App/services';

const FormContainer = styled.iframe`
	width: 100%;
	height: 100%;
	max-width: ${({ theme }) => TEXT_WIDTH_NARROW + theme.grid.gutter * 2}px;
	font-size: 15px;
	padding: 0;
	margin: ${WIDGET_SPACING_SMALL}px auto;
	display: block;
	border: 0;
	overflow: hidden;

	${media.tablet`
		padding: 0;
	`};

	${media.desktop1440`
    max-width: ${({ theme }) => TEXT_WIDTH + theme.grid.gutter * 2}px;
  `};
`;

type SupportedForms =
	| 'BabyFoodContactFormCOM'
	| 'ContactFormFI'
	| 'ContactFormCOM'
	| 'FastTrackWebinarCOM'
	| 'NewsletterFI'
	| 'NewsletterCOM'
	| 'TechnologyContactFormCOM'
	| 'WhitepaperLactoseFreeCOM'
	| 'WhitepaperPhospholipidsCOM'
	| 'JRH032021WebinarRecordingForm'
	| 'FSVitriinivinkit2021'
	| 'DigestiveWellnessWebinar';

const forms: Forms<SupportedForms> = {
	['BabyFoodContactFormCOM']: 'baby-food-contact-form.com',
	['ContactFormFI']: 'contact-form.fi',
	['ContactFormCOM']: 'contact-form.com',
	['FastTrackWebinarCOM']: 'fast-track-webinar.com',
	['NewsletterFI']: 'newsletter.fi',
	['NewsletterCOM']: 'newsletter.com',
	['TechnologyContactFormCOM']: 'technology-contact-form.com',
	['WhitepaperLactoseFreeCOM']: 'whitepaper-lactose-free.com',
	['WhitepaperPhospholipidsCOM']: 'whitepaper-phospholipids.com',
	['JRH032021WebinarRecordingForm']: 'JRH032021-webinar-recording-form',
	['FSVitriinivinkit2021']: 'FS-Vitriinivinkit-2021',
	['DigestiveWellnessWebinar']: 'digestive-wellness-webinar',
};

const SapForm: React.FC<SapFormStateProps & AppletCommonProps> = ({ environment, parameters }) => {
	const theme = React.useContext(ThemeContext);
	const iframeRef = React.useRef<HTMLIFrameElement>(null);

	const onLoad = () => {
		if (iframeRef.current) {
			iframeRef.current.style.height = iframeRef.current.contentWindow?.document.documentElement.scrollHeight
				? iframeRef.current.contentWindow?.document.documentElement.scrollHeight + theme.grid.gutter * 8 + 'px'
				: 0 + 'px';
		}
	};

	if (!forms[parameters]) {
		return null;
	}

	// const env = environment === 'prod' ? '.prod' : '';
	const env = '';

	return (
		<FormContainer src={`/content/Applets/SapForm/${forms[parameters]}${env}.html`} ref={iframeRef} onLoad={onLoad} />
	);
};

interface SapFormStateProps {
	environment?: string;
}

const mapStateToProps: MapStateToProps<SapFormStateProps, {}, State> = ({ app, routing }): SapFormStateProps => {
	return {
		environment: app.settings ? getSiteEnvironment(routing, app.settings.sites) : undefined,
	};
};

export default connect(mapStateToProps)(SapForm);
