import { FeedbackActions, feedbackActions } from './reducers';
import { productSearchApi } from 'common/components/GlobalSearch/services/product-search';
import { resourceApi } from 'common/components/Resource/services';
import { feedbackApi } from './service';
import { FeedbackApiModel } from './interfaces';

export const fetchProducts = async (siteUrlId: string, dispatch: React.Dispatch<FeedbackActions>) => {
	try {
		dispatch(feedbackActions.fetchProducts.request());
		const result = await productSearchApi.searchProducts(siteUrlId, '', 1000);
		dispatch(feedbackActions.fetchProducts.success(result));
	} catch (error) {
		dispatch(feedbackActions.fetchProducts.failure('applet_feedback_fetch_products_error'));
	}
};

export const fetchResource = async (
	siteUrlId: string,
	resourceUrl: string,
	dispatch: React.Dispatch<FeedbackActions>
) => {
	try {
		dispatch(feedbackActions.fetchResource.request());
		const result = await resourceApi.fetchResource(siteUrlId, resourceUrl);
		dispatch(feedbackActions.fetchResource.success(result));
	} catch (error) {
		dispatch(feedbackActions.fetchResource.failure('applet_feedback_fetch_resourceimage_error'));
	}
};

export const sendFeedback = async (
	siteUrlId: string,
	payload: FeedbackApiModel,
	dispatch: React.Dispatch<FeedbackActions>
) => {
	try {
		dispatch(feedbackActions.sendFeedback.request());
		await feedbackApi.sendFeedback(siteUrlId, payload);
		dispatch(feedbackActions.sendFeedback.success());
	} catch (error) {
		dispatch(
			feedbackActions.sendFeedback.failure({
				error: 'applet_feedback_send_feedback_error',
				modelState: !!error && !!error.details ? error.details.modelState : null,
			})
		);
	}
};

export const uploadFile = async (siteUrlId: string, payload: File, dispatch: React.Dispatch<FeedbackActions>) => {
	try {
		dispatch(feedbackActions.uploadImage.request());
		const apiResponse = await feedbackApi.uploadFile(siteUrlId, payload);
		dispatch(feedbackActions.uploadImage.success({ file: payload, apiData: apiResponse[0] }));
	} catch (error) {
		dispatch(feedbackActions.uploadImage.failure('applet_feedback_upload_attachment_error'));
	}
};
