import React, { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import SmallHeader from 'styleguide/components/Typography/SmallHeader';
import Tabs from 'styleguide/components/Tabs/Tabs';
import { TabProps } from 'styleguide/components/Tabs/Tab';
import { mediaBreakpoints } from 'styleguide/helpers';
import { connect, MapStateToProps } from 'react-redux';
import { State } from 'common/reducers';
import { getSiteUrlId } from 'common/components/App/services';
import { feedbackReducer, FeedbackState } from './reducers';
import { fetchProducts, fetchResource, sendFeedback, uploadFile } from './api';
import ComplaintForm from './components/Complaint';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import QuestionForm from './components/Question';
import { FeedbackFormData } from './interfaces';
import Confirmation from './components/Confirmation';

const FeedbackFormWrapper = styled.div`
	max-width: calc(${mediaBreakpoints.phone660}px + ${({ theme }) => theme.grid.gutter * 4}px);
	margin: ${({ theme }) => theme.grid.gutterInPx(4)} auto;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};

	.subheader {
		margin-top: 32px;
	}
`;

const enum TabType {
	Complaint = 'complaint',
	Question = 'question',
}

interface FeedbackStateProps {
	siteUrlId: string;
}

const initialState: FeedbackState = {
	products: undefined,
	isLoading: true,
	isSubmitting: false,
	isImageLoading: false,
	isImageBeingUploaded: false,
};

const Feedback: React.FC<FeedbackStateProps> = ({ siteUrlId }) => {
	const intl = useIntl();

	const [state, dispatch] = React.useReducer<typeof feedbackReducer>(feedbackReducer, initialState);

	const [activeTab, setActiveTab] = useState(TabType.Complaint);

	const tabs: TabProps[] = [
		{
			label: intl.formatMessage({ id: 'applet_feedback_form_type_complaint' }),
			url: TabType.Complaint,
			isActive: activeTab === TabType.Complaint,
		},
		{
			label: intl.formatMessage({ id: 'applet_feedback_form_type_question' }),
			url: TabType.Question,
			isActive: activeTab === TabType.Question,
		},
	];

	useEffect(() => {
		fetchProducts(siteUrlId, dispatch);
	}, []);

	const hasProducts = Boolean(state.products?.count);

	const onTabClick = (url: TabType) => {
		setActiveTab(url);
	};

	const onSubmit = (fieldValues: FeedbackFormData) => {
		sendFeedback(
			siteUrlId,
			{
				foreName: fieldValues.firstName,
				surName: fieldValues.lastName,
				email: fieldValues.email,
				phone: fieldValues.phone,
				replyNotRequested: fieldValues.answerNotRequired,
				selectedProductId: fieldValues.product,
				batchNumber: fieldValues.batchId,
				bestBefore: fieldValues.bestBeforeDate,
				feedbackText: fieldValues.message,
				placeOfPurchase: fieldValues.purchaseLocation,
				attachments: !!state.attachmentId ? [state.attachmentId] : [],
				isProductFeedback: fieldValues.isProductFeedback,
			},
			dispatch
		);
	};

	const onUpload = (file: File) => {
		uploadFile(siteUrlId, file, dispatch);
	};

	const onProductSelect = (e: ChangeEvent<HTMLSelectElement>) =>
		!!e.currentTarget?.value && fetchResource(siteUrlId, e.currentTarget.value, dispatch);

	const renderComplaintForm = () =>
		state.isLoading ? (
			<LoadingSpinner />
		) : (
			<ComplaintForm onSubmit={onSubmit} onUpload={onUpload} onProductSelect={onProductSelect} {...state} />
		);

	const renderForm = () => {
		if (state.isLoading) {
			return <LoadingSpinner />;
		}

		if (activeTab === TabType.Complaint && hasProducts) {
			return renderComplaintForm();
		}

		return <QuestionForm onSubmit={onSubmit} />;
	};

	const renderFeedback = () =>
		state.isSubmitted ? (
			<Confirmation />
		) : (
			<FeedbackFormWrapper>
				{!state.isLoading && hasProducts && (
					<>
						<SmallHeader tagName="h2" className="subheader">
							<FormattedMessage id="applet_feedback_form_type" />
						</SmallHeader>
						<Tabs tabs={tabs} onTabClick={onTabClick} useExternalProps />
					</>
				)}
				{renderForm()}
			</FeedbackFormWrapper>
		);

	return <>{renderFeedback()}</>;
};

const mapStateToProps: MapStateToProps<FeedbackStateProps, {}, State> = ({ app, routing }): FeedbackStateProps => {
	const sites = (app.settings && app.settings.sites) || [];
	return {
		siteUrlId: getSiteUrlId(routing, sites) || '',
	};
};

export default connect(mapStateToProps)(Feedback);
