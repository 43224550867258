import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form/dist/react-hook-form.ie11';
import { useIntl, FormattedMessage } from 'react-intl';
import Select from 'styleguide/components/Inputs/Select';
import Input from 'styleguide/components/Inputs/Input';
import Divider, { DividerDirection, DividerSize } from 'styleguide/components/Divider/Divider';
import SmallHeader from 'styleguide/components/Typography/SmallHeader';
import TextArea from 'styleguide/components/Inputs/TextArea';
import FileInput from 'styleguide/components/Inputs/FileInput';
import { ACCEPTED_FILE_FORMATS_IMAGES } from '../../common/EditorComponents';
import { ProductSearchResult } from 'common/components/GlobalSearch/services/product-search';
import PersonalData from './PersonalData';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import styled from 'styled-components';
import { FeedbackFormData } from '../interfaces';
import { FormActions, FormContainer } from '../../common/EditorComponents';
import { Button } from 'styleguide/components/Buttons/Button';
import includes from 'lodash/includes';
import ReCaptcha from 'common/components/ReCaptcha/ReCaptcha';
import { withWindow } from 'styleguide/helpers';

const FormContainerWithScrollableInputs = styled(FormContainer)`
	& input,
	& textarea {
		scroll-margin: 100px;
	}
`;

const RequiredDescriptionWrapper = styled.div`
	margin-top: 20px;
	margin-bottom: 20px;
`;

const ProductImageWrapper = styled.div`
	padding: 30px;
	margin: 0 auto;
	text-align: center;
`;

const CaptchaContainer = styled.div`
	margin-bottom: 15px;

	> div {
		display: inline-block;
		margin: 0 auto;
	}
`;

export interface ComplaintProps {
	onSubmit: (fieldValues: FeedbackFormData) => void;
	onProductSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
	onUpload: (file: File) => void;
	isLoading: boolean;
	isSubmitting?: boolean;
	products: ProductSearchResult | undefined;
	resourceImageUrl?: string;
	isImageLoading: boolean;
	previewImage?: string;
	error?: string | null;
	errorState?: any;
}

const ComplaintForm: React.FC<ComplaintProps> = ({
	onSubmit,
	onProductSelect,
	onUpload,
	isLoading,
	isSubmitting,
	products,
	resourceImageUrl,
	isImageLoading,
	previewImage,
	error,
	errorState,
}) => {
	const intl = useIntl();

	const methods = useForm<FeedbackFormData>({ defaultValues: { isProductFeedback: true } });
	const { handleSubmit, getValues, register, errors, clearError, setError } = methods;

	const renderImage = () =>
		isImageLoading ? <LoadingSpinner /> : !!resourceImageUrl && <img src={`${resourceImageUrl}/250x250-product`} />;

	const onDropImage = (file: File | null) => {
		clearError('files');
		if (!file) {
			setError('files', 'load', intl.formatMessage({ id: 'forms_input_file_upload_error' }));
			return;
		}
		if (!includes(['image/png', 'image/jpeg'], file.type)) {
			setError(
				'files',
				'type',
				intl.formatMessage(
					{ id: 'forms_input_file_accepted_files' },
					{ acceptedFiles: ACCEPTED_FILE_FORMATS_IMAGES.split(',').join(', ') }
				)
			);
			return;
		}
		onUpload(file);
	};

	const onUploadImage = () => {
		const { files } = getValues();
		if (files && files.length > 0) {
			onUpload(files[0]);
		}
	};

	const [isRecaptchaValid, setRecaptchaState] = useState<boolean>(false);

	const siteKey = withWindow(w => w.reCaptchaSiteKey);

	if (!!errorState) {
		const apiModelToFieldTranslator = {
			bestbefore: 'bestBeforeDate',
			batchnumber: 'batchId',
			feedbacktext: 'message',
			forename: 'firstName',
			surname: 'lastName',
			replynotrequested: 'answerNotRequired',
			selectedproductid: 'product',
			placeofpurchase: 'purchaseLocation',
		};
		for (const errorMember of Object.keys(errorState)) {
			const apiModelMemberName = errorMember.replace('model.', '');
			// lowercase, because API seemd to return inconsistent casing
			const fieldName = apiModelToFieldTranslator[apiModelMemberName.toLowerCase()] || apiModelMemberName;
			setError(fieldName, 'validation', intl.formatMessage({ id: 'global_error' }));
		}
	} else if (!!error) {
		setError('files', 'attach', intl.formatMessage({ id: error }));
	}

	useScrollToError(errors);

	const requiredDescription = intl.formatMessage({ id: 'applet_feedback_form_validation_required_description' });

	const complaintSubmit = (data: FeedbackFormData) => {
		const complaintData = { ...data, isProductFeedback: true };
		onSubmit(complaintData);
	};

	return (
		<FormContext {...methods}>
			{requiredDescription.trim().length > 0 && (
				<RequiredDescriptionWrapper>{requiredDescription}</RequiredDescriptionWrapper>
			)}
			<FormContainerWithScrollableInputs onSubmit={handleSubmit(complaintSubmit)}>
				<Select
					name="product"
					ref={register({ required: intl.formatMessage({ id: 'applet_feedback_form_validation_required' }) })}
					onChange={onProductSelect}
					validationError={errors.product?.message}>
					<option value="">{intl.formatMessage({ id: 'applet_feedback_form_field_chooseproduct' })}</option>
					{!isLoading &&
						products?.items.map((i, idx) => (
							<option key={idx} value={i.url}>
								{i.title}
							</option>
						))}
				</Select>
				<ProductImageWrapper>{renderImage()}</ProductImageWrapper>
				<Input
					type="text"
					name="purchaseLocation"
					ref={register({ required: intl.formatMessage({ id: 'applet_feedback_form_validation_required' }) })}
					placeholder={intl.formatMessage({ id: 'applet_feedback_form_field_storename' })}
					validationError={errors.purchaseLocation?.message}
				/>
				<Input
					type="date"
					name="bestBeforeDate"
					ref={register}
					validationError={errors.bestBeforeDate?.message}
					label={intl.formatMessage({ id: 'applet_feedback_form_field_bestbefore' })}
					placeholder={intl.formatMessage({ id: 'applet_feedback_form_field_bestbefore' })}
				/>
				<Input
					type="text"
					name="batchId"
					ref={register}
					validationError={errors.batchId?.message}
					placeholder={intl.formatMessage({ id: 'applet_feedback_form_field_productcode' })}
				/>
				<Divider direction={DividerDirection.horizontal} size={DividerSize.full} />
				<SmallHeader tagName="h2" className="subheader">
					<FormattedMessage id="applet_feedback_form_questionsorcomments" />
				</SmallHeader>
				<TextArea
					name="message"
					placeholder={intl.formatMessage({ id: 'applet_feedback_form_field_message_required' })}
					ref={register({ required: intl.formatMessage({ id: 'applet_feedback_form_validation_required' }) })}
					validationError={errors.message?.message}
				/>
				<FileInput
					name="files"
					label={intl.formatMessage({ id: 'applet_feedback_form_field_image_upload' })}
					helpText={intl.formatMessage(
						{ id: 'applet_feedback_form_field_image_upload_help' },
						{ acceptedFiles: ACCEPTED_FILE_FORMATS_IMAGES.split(',').join(', ') }
					)}
					ref={register}
					acceptFiles={ACCEPTED_FILE_FORMATS_IMAGES}
					previewImageUrl={previewImage}
					showPreviewImage={!!previewImage}
					validationError={errors.files?.message}
					onDropImage={onDropImage}
					onUploadImage={onUploadImage}
				/>
				<Divider direction={DividerDirection.horizontal} size={DividerSize.full} />
				<PersonalData />
				<FormActions>
					<CaptchaContainer>
						<ReCaptcha resultCallback={setRecaptchaState} siteKey={siteKey} expiredCallback={setRecaptchaState} />
					</CaptchaContainer>
					{isRecaptchaValid && !isSubmitting && (
						<Button type="submit">
							<FormattedMessage id="applet_feedback_submit" />
						</Button>
					)}
					{isSubmitting && <LoadingSpinner />}
				</FormActions>
			</FormContainerWithScrollableInputs>
		</FormContext>
	);
};

function useScrollToError(errors: any) {
	useEffect(() => {
		const errorsvalues = Object.values(errors);
		if (errorsvalues.length > 0 && !!(errorsvalues[0] as any).ref) {
			(errorsvalues[0] as any).ref.scrollIntoView({ behavior: 'smooth' });
		}
	});
}

export default ComplaintForm;
