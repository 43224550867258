import * as React from 'react';
import HighlightedLink from 'styleguide/components/Links/HighlightedLink/HighlightedLink';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import HighlightedLinkSection from 'common/components/HighlightedLinkSection/HighlightedLinkSection';
import { FormattedMessage } from 'react-intl';

interface SiteDefinitionProps {
	siteUrlPrefix: string;
}

type ClassificationsProps = SiteDefinitionProps & InjectedRoutableProps;

const Classifications: React.FC<ClassificationsProps> = ({ siteUrlPrefix }) => {
	return (
		<HighlightedLinkSection>
			<HighlightedLink href={`${siteUrlPrefix}tuotteet/haku/?ominaisuudet=laktoositon`} target="">
				<FormattedMessage id="product_classification_lactose_free" />
			</HighlightedLink>
			<HighlightedLink href={`${siteUrlPrefix}tuotteet/haku/?brandi=valio-luomu`} target="">
				<FormattedMessage id="product_classification_organic" />
			</HighlightedLink>
			<HighlightedLink href={`${siteUrlPrefix}tuotteet/haku/?ominaisuudet=vahemman-suolaa`} target="">
				<FormattedMessage id="product_classification_less_salt" />
			</HighlightedLink>
			<HighlightedLink href={`${siteUrlPrefix}tuotteet/haku/?ominaisuudet=runsaasti-proteiinia`} target="">
				<FormattedMessage id="product_classification_protein_rich" />
			</HighlightedLink>
			<HighlightedLink href={`${siteUrlPrefix}tuotteet/haku/?ominaisuudet=gluteeniton`} target="">
				<FormattedMessage id="product_classification_gluten_free" />
			</HighlightedLink>
			<HighlightedLink href={`${siteUrlPrefix}tuotteet/haku/?ominaisuudet=maidoton`} target="">
				<FormattedMessage id="product_classification_dairy_free" />
			</HighlightedLink>
			<HighlightedLink href={`${siteUrlPrefix}tuotteet/haku/?ominaisuudet=lisaaineeton`} target="">
				<FormattedMessage id="product_classification_additive_free" />
			</HighlightedLink>
			<HighlightedLink href={`${siteUrlPrefix}tuotteet/haku/?ominaisuudet=sydanmerkki`} target="">
				<FormattedMessage id="product_classification_heart_symbol" />
			</HighlightedLink>
			<HighlightedLink href={`${siteUrlPrefix}tuotteet/haku/?muut-sisaltaa=lgg-maitohappobakteeria`} target="">
				<FormattedMessage id="product_classification_lgg_lactic_acid_bacteria" />
			</HighlightedLink>
			<HighlightedLink href={`${siteUrlPrefix}tuotteet/haku/?ominaisuudet=hyvaa-suomesta`} target="">
				<FormattedMessage id="product_classification_quality_from_finland" />
			</HighlightedLink>
			<HighlightedLink href={`${siteUrlPrefix}tuotteet/haku/?ominaisuudet=vegaaninen`} target="">
				<FormattedMessage id="product_classification_vegan" />
			</HighlightedLink>
		</HighlightedLinkSection>
	);
};

export default injectRoutable(Classifications);
