import * as React from 'react';
import styled from 'styled-components';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import Ingress from 'styleguide/components/Typography/Ingress';
import Classifications from './components/Classifications';
import IngredientSelectors from './components/IngredientSelectors';
import { ProductFeatures } from './interfaces';
import { WIDGET_SPACING_MEDIUM } from 'common/components/Widgets/constants';
import { MapStateToProps, connect } from 'react-redux';
import { AppletCommonProps } from '../types';
import { State } from 'common/reducers';
import { ProductFrontState } from 'pagetypes/ProductFront/reducers/reducers';
import { getSiteUrlPrefixOrDefault } from 'common/components/App/services';

const FeaturesAndIngredientsWrapper = styled.div`
	margin: ${WIDGET_SPACING_MEDIUM}px auto;
`;

const FeaturesAndIngredientsHeader = styled(MediumHeader)`
	text-align: center;
`;

type Props = AppletCommonProps & StateProps;

const ProductFeatureBrowser: React.FC<Props> = ({ siteUrlPrefix, aspects }) => {
	const intl = useIntl();

	const onIngredientSelect = (value: string, filter: string) => {
		window.location.href = `${siteUrlPrefix}${intl.formatMessage({ id: 'url_product_search' })}?${filter}=${value}`;
	};
	return (
		<FeaturesAndIngredientsWrapper>
			<FeaturesAndIngredientsHeader>
				<FormattedMessage id="product_features_and_ingredients" />
			</FeaturesAndIngredientsHeader>
			<Ingress>
				<FormattedMessage id="product_features_and_ingredients_lead_text" />
			</Ingress>
			<Classifications siteUrlPrefix={siteUrlPrefix} />
			<IngredientSelectors aspects={aspects} onIngredientSelect={onIngredientSelect} />
		</FeaturesAndIngredientsWrapper>
	);
};

interface StateProps {
	aspects: ProductFeatures;
	siteUrlPrefix: string;
}

const mapStateToProps: MapStateToProps<StateProps, AppletCommonProps, State> = ({
	resource,
	routing,
	app,
}): StateProps => {
	const productFront = resource.content as ProductFrontState;
	const sites = (app.settings && app.settings.sites) || [];
	return {
		aspects: productFront?.aspects,
		siteUrlPrefix: getSiteUrlPrefixOrDefault(routing, sites, '/'),
	};
};

export default connect(mapStateToProps)(ProductFeatureBrowser);
