import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SmallHeader from 'styleguide/components/Typography/SmallHeader';
import Input from 'styleguide/components/Inputs/Input';
import Checkbox from 'styleguide/components/Inputs/Checkbox';
import { useFormContext } from 'react-hook-form/dist/react-hook-form.ie11';
import styled from 'styled-components';
import { FeedbackFormData } from '../interfaces';

const PrivacyPolicyLink = styled.a``;

const PersonalData: React.FC = () => {
	const intl = useIntl();
	const { register, errors } = useFormContext<FeedbackFormData>();

	return (
		<>
			<SmallHeader tagName="h2" className="subheader">
				<FormattedMessage id="applet_feedback_form_personaldata" />
			</SmallHeader>
			<Input
				type="text"
				name="firstName"
				ref={register({ required: intl.formatMessage({ id: 'applet_feedback_form_validation_required' }) })}
				placeholder={intl.formatMessage({ id: 'applet_feedback_form_field_firstname' })}
				validationError={errors.firstName?.message}
			/>
			<Input
				type="text"
				name="lastName"
				ref={register({ required: intl.formatMessage({ id: 'applet_feedback_form_validation_required' }) })}
				placeholder={intl.formatMessage({ id: 'applet_feedback_form_field_lastname' })}
				validationError={errors.lastName?.message}
			/>
			<Input
				type="text"
				name="email"
				ref={register({
					required: intl.formatMessage({ id: 'applet_feedback_form_validation_required' }),
				})}
				placeholder={intl.formatMessage({ id: 'applet_feedback_form_field_email' })}
				validationError={errors.email?.message}
			/>
			<Input
				type="text"
				name="phone"
				ref={register}
				placeholder={intl.formatMessage({ id: 'applet_feedback_form_field_phone' })}
			/>
			<Checkbox name="answerNotRequired" ref={register}>
				<FormattedMessage id="applet_feedback_form_field_answernotrequired" />
			</Checkbox>
			<PrivacyPolicyLink href={intl.formatMessage({ id: 'applet_feedback_link_privacypolicy_url' })}>
				<FormattedMessage id="applet_feedback_link_privacypolicy" />
			</PrivacyPolicyLink>
		</>
	);
};

export default PersonalData;
