import * as React from 'react';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import Input from 'styleguide/components/Inputs/Input';
import { useIntl, FormattedMessage } from 'react-intl';
import { ValidationPatterns } from 'common/components/Forms/helpers';
import SmallHeader from 'styleguide/components/Typography/SmallHeader';
import { Button } from 'styleguide/components/Buttons/Button';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';
import { ContentWrapper } from 'common/components/General';
import { WIDGET_SPACING_MEDIUM, TEXT_WIDTH_NARROW, TEXT_WIDTH } from 'common/components/Widgets/constants';
import { CouponStates } from './interface';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';
import Alert, { AlertType } from 'styleguide/components/Typography/Alert';
import { clearOnApi } from './services';
import { MapStateToProps, connect } from 'react-redux';
import { AppletCommonProps } from '../types';
import { State } from 'common/reducers';
import { getSiteUrlId } from 'common/components/App/services';

const ClearOnWrapper = styled.article`
	background: ${({ theme }) => theme.colors.contentSectionHiglighted};
	text-align: center;
	padding: ${WIDGET_SPACING_MEDIUM}px 0;
`;

const Form = styled.form`
	width: 100%;
	max-width: ${TEXT_WIDTH_NARROW}px;
	margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};

	${media.desktop1440`
    max-width: ${TEXT_WIDTH}px;
  `};

	.coupon-error {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};
	}

	.loading-spinner {
		margin: ${({ theme }) => theme.grid.gutterInPx(3)} auto;
	}
`;

const Fields = styled.div`
	width: 100%;

	${media.tablet`
		display: flex;		

		.form-field {
			flex: 1 1 calc(50% - ${({ theme }) => theme.grid.gutterInPx(3)});

			& + .form-field {
				margin-left: ${({ theme }) => theme.grid.gutterInPx(3)};
			}

			.help-text {
				text-align: left;
			}
		}
	`};
`;

const SubmitButton = styled(Button)`
	margin: ${({ theme }) => theme.grid.gutterInPx(3)} auto 0;
`;

interface ClearOnFormData {
	email?: string;
	mobile?: string;
}

type Props = StateProps & AppletCommonProps;

const ClearOn: React.FC<Props> = ({ siteUrlId, parameters }) => {
	const intl = useIntl();

	const [couponState, setCouponState] = React.useState<CouponStates>('COUPON_FORM');
	const { handleSubmit, errors, register, getValues, reset, watch } = useForm<ClearOnFormData>();

	const onSubmit = async (values: ClearOnFormData) => {
		try {
			setCouponState('COUPON_REQUEST');
			await clearOnApi.orderCoupon(siteUrlId, { campaignId: parameters, email: values.email, mobile: values.mobile });
			setCouponState('COUPON_CONFIRMATION');
			reset();
		} catch (error) {
			setCouponState('COUPON_ERROR');
		}
	};

	const conditionallyRequired = (message: string, value?: string, otherValue?: string) => {
		if (!value && !otherValue) {
			return message;
		}
		return true;
	};

	let titleId = 'applet_clearon_title';
	if (couponState === 'COUPON_CONFIRMATION') {
		titleId = 'applet_clearon_confirmation_title';
	}

	const watchEmail = watch('email');
	const watchMobile = watch('mobile');

	return (
		<ClearOnWrapper>
			<ContentWrapper>
				<SmallHeader>
					<FormattedMessage id={titleId} />
				</SmallHeader>
				{couponState !== 'COUPON_CONFIRMATION' && (
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Fields>
							<Input
								type="email"
								name="email"
								placeholder={intl.formatMessage({ id: 'forms_email_address' })}
								validationError={errors.email?.message}
								helpText={intl.formatMessage({ id: 'applet_clearon_email_help' })}
								disabled={!!watchMobile}
								ref={register({
									validate: value =>
										conditionallyRequired(
											intl.formatMessage({ id: 'applet_clearon_email_mobile_error' }),
											value,
											getValues().mobile
										),
									pattern: {
										value: new RegExp(ValidationPatterns.EMAIL),
										message: intl.formatMessage({ id: 'forms_validation_email_check' }),
									},
								})}
							/>
							<Input
								type="text"
								name="mobile"
								placeholder={intl.formatMessage({ id: 'applet_clearon_mobile' })}
								validationError={errors.mobile?.message}
								helpText={intl.formatMessage({ id: 'applet_clearon_mobile_help' })}
								disabled={!!watchEmail}
								ref={register({
									validate: value =>
										conditionallyRequired(
											intl.formatMessage({ id: 'applet_clearon_email_mobile_error' }),
											value,
											getValues().email
										),
								})}
							/>
						</Fields>
						{couponState === 'COUPON_ERROR' && (
							<Alert
								type={AlertType.Error}
								className="coupon-error"
								highlightedText={`${intl.formatMessage({ id: 'global_error' })}:`}>
								{intl.formatMessage({ id: 'applet_clearon_send_error' })}
							</Alert>
						)}
						{couponState === 'COUPON_REQUEST' && <LoadingSpinner className="loading-spinner" />}
						<SubmitButton type="submit" disabled={couponState === 'COUPON_REQUEST'}>
							Get coupon
						</SubmitButton>
					</Form>
				)}
				{couponState === 'COUPON_CONFIRMATION' && intl.formatMessage({ id: 'applet_clearon_confirmation' })}
			</ContentWrapper>
		</ClearOnWrapper>
	);
};

interface StateProps {
	siteUrlId: string;
}

const mapStateToProps: MapStateToProps<StateProps, AppletCommonProps, State> = ({ routing, app }): StateProps => {
	const sites = app.settings?.sites || [];
	const siteUrlId = getSiteUrlId(routing, sites)!;
	return {
		siteUrlId,
	};
};

export default connect(mapStateToProps)(ClearOn);
