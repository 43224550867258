import React from 'react';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import { FormattedMessage } from 'react-intl';

const Confirmation: React.FC = () => (
	<MediumHeader>
		<FormattedMessage id="applet_feedback_submit_confirmation" />
	</MediumHeader>
);

export default Confirmation;
