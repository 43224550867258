import * as React from 'react';
import styled from 'styled-components';
import { WIDGET_SPACING_SMALL } from 'common/components/Widgets/constants';
import { AppletCommonProps } from '../types';
import { Helmet } from 'react-helmet';

const FormContainer = styled.iframe`
	width: 100%;
	font-size: 15px;
	padding: 0;
	margin: ${WIDGET_SPACING_SMALL}px auto;
	display: block;
	border: 0;
	overflow: hidden;
`;

const Consulink: React.FC<AppletCommonProps> = ({ parameters: baseUrl }) => {
	// iframe
	// https://www.frieslandcampinaconsumentenservice.com/OddlyGood-NL-Service/nl/search/

	// script
	// https://www.frieslandcampinaconsumentenservice.com/OddlyGood-NL-Service/resources/js/iframeMessageReceiver.js

	return (
		<>
			<Helmet>
				<meta name="referrer" content="no-referrer-when-downgrade" />
				<script src={`${baseUrl}/resources/js/iframeMessageReceiver.js`} />
			</Helmet>
			<FormContainer
				src={`${baseUrl}/nl/search/`}
				id="contactIframe"
				// @ts-ignore
				referrerPolicy="origin-when-cross-origin"
				frameBorder="0"
			/>
		</>
	);
};

export default Consulink;
