import { getWeeklyMenu } from 'common/components/WeeklyMenu/services';
import { weeklyMenuAppletActions, WeeklyMenuAppletActions } from './reducers';

export const fetchWeeklyMenu = async (siteUrlId: string, dispatch: React.Dispatch<WeeklyMenuAppletActions>) => {
	try {
		const result = await getWeeklyMenu(siteUrlId);
		dispatch(weeklyMenuAppletActions.fetchWeeklyMenu.success(result));
	} catch (error) {
		dispatch(weeklyMenuAppletActions.fetchWeeklyMenu.failure('applet_weekly_menu_error'));
	}
};
