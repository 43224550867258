import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';
import { getScrollbarWidth } from 'utils/document';
import { ContentWrapper } from 'common/components/General';
import { MapStateToProps, connect } from 'react-redux';
import { State } from 'common/reducers';
import { getSiteUrlPrefixOrDefault, getSiteUrlId } from 'common/components/App/services';
import { weeklyMenuAppletReducer, weeklyMenuAppletActions } from './reducers';
import { WeeklyMenuState } from 'common/components/WeeklyMenu/interfaces';
import WeeklyMenu from 'common/components/WeeklyMenu/WeeklyMenu';
import { fetchWeeklyMenu } from './api';
import LoadingSpinner from 'common/components/Loading/LoadingSpinner';

const WeeklyMenuSection = styled.section`
	background: ${({ theme }) => theme.colors.widgetZoneFullWidthHighlighted};
	width: 100vw;

	${media.desktop`
		width: calc(100vw - ${getScrollbarWidth()}px);
	`};
`;

const initialState: WeeklyMenuState = {
	isLoading: false,
};

const WeeklyMenuApplet: React.FC<WeeklyMenuAppletStateProps> = ({ siteUrlPrefix, siteUrlId }) => {
	const [state, dispatch] = React.useReducer<typeof weeklyMenuAppletReducer>(weeklyMenuAppletReducer, initialState);
	const { isLoading, error, weeklyMenu } = state;

	React.useEffect(() => {
		dispatch(weeklyMenuAppletActions.fetchWeeklyMenu.request());
		fetchWeeklyMenu(siteUrlId, dispatch);
	}, []);

	return (
		<WeeklyMenuSection>
			<ContentWrapper>
				{isLoading && <LoadingSpinner />}
				{!isLoading && !error && weeklyMenu && <WeeklyMenu {...state} siteUrlPrefix={siteUrlPrefix} isStatic={true} />}
			</ContentWrapper>
		</WeeklyMenuSection>
	);
};

interface WeeklyMenuAppletStateProps {
	siteUrlPrefix: string;
	siteUrlId: string;
}

const mapStateToProps: MapStateToProps<WeeklyMenuAppletStateProps, {}, State> = ({
	app,
	routing,
}): WeeklyMenuAppletStateProps => {
	const sites = (app.settings && app.settings.sites) || [];
	return {
		siteUrlPrefix: getSiteUrlPrefixOrDefault(routing, sites, '/'),
		siteUrlId: getSiteUrlId(routing, sites) || '',
	};
};

export default connect(mapStateToProps)(WeeklyMenuApplet);
